<template>
  <form @submit.prevent="submitHandler" v-form-enter>
    <div style="padding-bottom: 150px">
      <div class="formgrid p-fluid grid flex align-items-center pb-3 pt-3">
        <div class="field col-12 sm:col-5">
          <label for="name">Accredit time to</label>
          <div v-if="users.length > 0" class="p-inputgroup">
            <Dropdown
              v-model="timeUser"
              :options="users"
              style="width: 100%"
              optionLabel="name"
              placeholder="Select a user"
              dataKey="uid"
            />
          </div>
          <div v-else class="p-inputgroup">
            <Dropdown disabled placeholder="No users assigned yet" />
          </div>
        </div>
        <div class="field col-12 sm:col-5">
          <label for="name">Time was spent on</label>
          <Calendar
            autocomplete="off"
            v-model="date"
            :maxDate="maximumDate"
            :minDate="minDate"
            :showIcon="true"
            placeholder="Time was spent on"
            :selectOtherMonths="true"
            :showOtherMonths="true"
            dateFormat="yy-mm-dd"
          />
        </div>
        <div class="field col-12 sm:col-2">
          <label for="name">Time</label>
          <div class="p-inputgroup">
            <InputMask
              placeholder="Time"
              v-model="time"
              mask="99:99"
              style="width: 100%"
            />
          </div>
        </div>

        <div class="field col-12">
          <label for="name">Description</label>

          <Textarea
            id="Description"
            placeholder="Description"
            :autoResize="true"
            rows="5"
            v-model="desc"
          />
        </div>
      </div>

      <div class="flex justify-content-between">
        <Button type="submit" v-if="editModel" label="Save time" />
        <Button type="submit" v-else label="Add time" />
        <Button class="p-button-text p-button-secondary" @click="dismiss()">
          CANCEL
        </Button>
      </div>
    </div>
  </form>
</template>

<script>
import {
  fetchTaskTimes,
  fetchTaskUsers,
} from "../../../services/data_service.js";
export default {
  data() {
    return {
      desc: null,
      time: null,
      date: null,
      timeUser: null,
      edit: null,
    };
  },
  props: {
    users: Array,
    task: Object,
    job_uid: String,
    editModel: Object,
  },
  mounted() {
    if (this.editModel) {
      this.desc = this.editModel.commit_message;

      this.time =
        this.editModel.commit_time > 0
          ? this.convertMinsToHrsMins(this.editModel.commit_time)
          : this.convertMinsToHrsMins(this.editModel.time_spent);

      this.date = new Date(
        this.editModel.commit_date
          ? this.editModel.commit_date
          : this.editModel.timer_start * 1000
      );

      for (var x = 0; x < this.users.length; x++) {
        if (this.users[x].uid === this.editModel.user_uid) {
          this.timeUser = this.users[x];
          return;
        }
      }
    }
  },
  methods: {
    dismiss() {
      this.$emit("dismiss");
    },
    async submitHandler() {
      if (!this.timeUser || !this.date || !this.time || !this.desc) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all fields",
          life: 3000,
        });
        return;
      }

      var form = new FormData();
      var a = this.time.split(":");
      var minutes = +a[0] * 60 + +a[1];

      if (this.editModel) {
        form.append("id", this.editModel.id);
      }

      this.$emit("isLoading");

      form.append("task", this.task.uid);
      form.append("commit_date", this.$moment(this.date).format("YYYYMMDD"));
      form.append("commit_minutes", minutes);
      form.append("commit_message", this.desc);
      form.append("user_uid", this.timeUser.uid);
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasks/time" +
            (this.editModel ? "/" + this.editModel.id : ""),
          form,
          {
            headers: { "Content-type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {
          fetchTaskTimes(this.task.uid);
          fetchTaskUsers(this.task.uid);
          this.$emit("dismiss");
          this.timeUser = null;
          this.date = null;
          this.time = null;
          this.desc = null;
          this.$toast.add({
            severity: "success",
            summary: "Time Saved",
            life: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    date_start() {
      return this.task.date_start?.date;
    },
    maximumDate() {
      return new Date();
    },
    minDate() {
      return new Date(this.task.date_start?.date);
    },
  },
};
</script>

<style>
</style>