<template>
  <div class="formgrid p-fluid grid flex align-items-center">
    <div :class="'col-5 field ' + (user.active == 1 ? '' : 'opacity-50')">
      <div class="flex align-items-center">
        <ProgressAvatar
          :image="user.profile_picture"
          :user="user"
          :progress="user.progress_bar"
          :strokeColor="user.progress_bar >= 100 ? '#fe4d97' : '#6dd230'"
          innerStrokeColor="#ffffff"
          :radius="25"
          class="mr-2"
        ></ProgressAvatar
        >{{ user.name }} {{ user.surname }}
      </div>
    </div>

    <div :class="'col-7 field ' + (user.active == 1 ? '' : 'opacity-50')">
      <div class="flex align-items-center justify-content-between">
        <div class="col">
          <div
            v-if="user.estimated_hours"
            class="flex justify-content-between align-items-end"
          >
            <span
              :style="getTagStyle(user) + ';borderRadius: 6px'"
              class="tag-chip"
            >
              {{
                user.in_review == 1
                  ? "In Review"
                  : user.is_complete == 1
                  ? "Complete"
                  : "Active"
              }}
            </span>

            {{ !user.hours ? "00:00" : user.hours }} /
            {{ user.estimated_hours }}
          </div>
          <div class="flex align-items-center mt-1" v-if="user.estimated_hours">
            <div
              class="surface-300 border-round overflow-hidden w-full"
              style="height: 6px"
            >
              <div class="h-full" :style="getProgressStyle(user)" />
            </div>
          </div>
        </div>
        <Menu :model="items" ref="assigneeMenu" :popup="true" appendTo="body" />
        <i class="las la-ellipsis-h text-20 cursor-pointer" @click="toggle" />

        <!-- <div class="col flex-grow-0">
            <AActiveSwitch @change="toggleUser(user.uid)" v-model="user.active" />
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
    task: Object,
  },
  data() {
    return {
      contextMenuItems: [
        {
          label: "Deactivate User",
          command: () => {
            this.toggleUser(this.user.uid);
          },
        },
        {
          label: "Reactivate User",
          command: () => {
            this.toggleUser(this.user.uid);
          },
        },
        {
          label: "Mark Completed",
          command: () => {
            this.toggleComplete(this.user.uid);
          },
        },
        {
          label: "Activate Task",
          command: () => {
            this.toggleComplete(this.user.uid);
          },
        },
      ],
    };
  },
  methods: {
    toggle(event) {
      this.$refs.assigneeMenu.toggle(event);
    },
    getTagStyle(u) {
      if (u.is_complete == 1) {
        return "text-align: center; background: #FFF1F7 !important; color: #778ca2 !important; padding: 3px";
      } else if (u.in_review == 1) {
        return "text-align: center; background: #fff2de !important;color:#FFAB2B !important; padding: 3px";
      }
      return "text-align: center; background: #f4fbef !important;color: rgb(109, 210, 48)  !important; padding: 3px";
    },
    getProgressStyle(task) {
      if (task.progress_bar >= 100) {
        return "background: #fe4d97;border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;";
      } else {
        return (
          "border-bottom-left-radius: 4px;background: #6dd230;width:" +
          (task.progress_bar ? parseInt(task.progress_bar) : 0) +
          "%"
        );
      }
    },
    getContextMenu(array) {
      var arr = [];
      array.forEach((element) => {
        arr.push(this.contextMenuItems[element]);
      });
      return arr;
    },
    toggleUser(user_uid) {
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasks/" +
            this.task.uid +
            "/assign/" +
            user_uid,
          {}
        )
        .then((response) => {
          this.$bus.emit("refreshData");
        })

        .catch((error) => {
          console.error(error);
        });
    },
    toggleComplete(user_uid) {
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasks/" +
            this.task.uid +
            "/complete/" +
            user_uid,
          {}
        )
        .then((response) => {
          this.$bus.emit("refreshData");
        })

        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    items() {
      if (this.user.is_complete === 1 && this.user.active === 1) {
        return this.getContextMenu([3, 0]);
      }
      if (this.user.is_complete != 1 && this.user.active === 1) {
        return this.getContextMenu([2, 0]);
      }
      if (this.user.is_complete === 1 && this.user.active !== 1) {
        return this.getContextMenu([3, 1]);
      }
      if (this.user.is_complete != 1 && this.user.active !== 1) {
        return this.getContextMenu([2, 1]);
      }

      return [];
    },
  },
};
</script>

<style>
</style>