<template>
  <div class="mt-2">
    <Button
      v-if="!task?.hasOwnProperty('brief') && !addBrief"
      icon="las la-plus"
      @click="
        addBrief = true;
        addAmendment = false;
      "
      label="Add Brief"
      class="p-button-text p-button-secondary"
    />

    <Button
      v-if="!addAmendment"
      icon="las la-plus"
      @click="
        addAmendment = true;
        addBrief = false;
      "
      label="Add Amendement"
      class="p-button-text p-button-secondary"
    />

    <TaskAmendments
      v-if="!addBrief"
      @cancel="addAmendment = false"
      :addAmendment="addAmendment"
      :taskProp="task"
    ></TaskAmendments>
    <TaskBrief
      v-if="!addAmendment"
      @cancel="addBrief = false"
      :addBrief="addBrief"
      :taskProp="task"
    ></TaskBrief>
  </div>
</template>
<script>
import { fetchAmendments } from "../../../services/data_service.js";
import TaskBrief from "@/application/Tasks/components/TaskBrief";
import TaskAmendments from "@/application/Tasks/components/TaskAmendments";
export default {
  name: "TaskBriefAmendments",
  props: {
    task: {
      type: Object,
    },
  },
  components: { TaskBrief, TaskAmendments },
  data() {
    return {
      addBrief: false,
      addAmendment: false,
    };
  },
  async mounted() {
    await fetchAmendments(this.task.uid);
  },
  methods: {
    checkIfBriefExists() {
      return this.task?.brief && this.task.brief.length > 0;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>