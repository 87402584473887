<template>
  <div class="mt-2">
    <div class="formgrid grid flex align-items-center" v-if="!addNote">
      <Button
        label="Add a Note"
        class="p-button-text p-button-secondary"
        @click="addNote = !addNote"
        icon="las la-plus"
      />
      <Divider class="mb-3 ml-2 mr-2"></Divider>
    </div>
    <form
      class="formgrid grid flex align-items-center"
      v-if="addNote"
      @submit.prevent="submitHandler"
      v-form-enter
    >
      <div class="field col-12 mt-3">
        <Mentionable
          @apply="handleApply(null)"
          class="border-4"
          insert-space
          offset="6"
          :keys="['@']"
          :items="users"
        >
          <Textarea
            class="w-full"
            ref="comment"
            v-model="comment"
            placeholder="Note"
            :autoResize="true"
            rows="5"
          />
          <template #no-result>
            <div class="flex align-items-center p-3 text-10">No Results</div>
          </template>
          <template #item-@="{ item }">
            <div class="flex align-items-center p-3 cursor-pointer">
              <div class="flex-grow-0">
                <Avatar
                  :image="item.profile_picture"
                  :label="!item.profile_picture ? getIntials(item) : null"
                  :style="{
                    'background-color': !item.profile_picture
                      ? getColorFromName(item)
                      : 'none',
                    color: '#ffffff',
                  }"
                  size="large"
                  shape="circle"
                  class="mr-3"
                />
              </div>
              <div class="flex flex-column">
                <div>{{ item.name }} {{ item.surname }}</div>
                <div class="text-10">@{{ item.value }}</div>
              </div>
            </div>
          </template>
        </Mentionable>
      </div>
      <div class="field col-12 cursor-pointer">
        <div class="flex justify-content-between pt-3 pb-3">
          <Button type="submit"> ADD NOTE </Button>
          <Button
            class="p-button-text p-button-secondary"
            @click="addNote = false"
          >
            CANCEL
          </Button>
        </div>
      </div>
    </form>
    <div class="p-fluid formgrid grid mt-3" v-for="n in notes" :key="n.uid">
      <div class="field col-12">
        <div class="flex align-items-center">
          <div class="col flex-grow-0">
            <Avatar
              size="large"
              :style="{
                'background-color': '#F2F4F6',
                color: '#B9C5D0',
              }"
              shape="circle"
              :image="n.user.profile_picture"
            />
          </div>
          <div class="col flex flex-column">
            <div>{{ n.user.name }}</div>

            <div class="font-light text-sm">{{ n.created_date.nice }}</div>
          </div>
        </div>
      </div>
      <div
        v-if="n.title && n.title !== 'null'"
        class="field col-12"
        style="font-weight: bold"
      >
        <div class="pl-3">{{ n.title }}</div>
      </div>
      <div class="field col-12">
        <div class="pl-3" v-html="handleNoteTags(n.note_text)"></div>
      </div>

      <div
        class="field col-12 ml-4 border-left"
        :key="c.uid"
        v-for="c in n.comments"
      >
        <div class="field col-12 mb-2">
          <div class="flex align-items-center pl-2">
            <div class="col flex flex-column">
              <div>{{ c.user.name }}</div>
              <div class="font-light text-sm">{{ c.created_date.nice }}</div>
            </div>
          </div>
        </div>

        <div class="field col-12">
          <div class="pl-3" v-html="handleNoteTags(c.note_text)"></div>
        </div>
      </div>
      <div class="field col-12 pl-5">
        <Mentionable
          v-if="addReply === n.uid"
          @apply="handleApply('reply' + n.uid)"
          insert-space
          offset="6"
          :keys="['@']"
          :items="users"
        >
          <template #item-@="{ item }">
            <!-- {{ item }} -->
            <div class="flex align-items-center p-3 cursor-pointer">
              <div class="flex-grow-0">
                <Avatar
                  :image="item.profile_picture"
                  :label="!item.profile_picture ? getIntials(item.name) : null"
                  :style="{
                    'background-color': !item.profile_picture
                      ? getColorFromName(item)
                      : 'none',
                    color: '#ffffff',
                  }"
                  size="large"
                  shape="circle"
                  class="mr-3"
                />
              </div>
              <div class="flex flex-column">
                <div>{{ item.name }} {{ item.surname }}</div>
                <div class="text-10">@{{ item.value }}</div>
              </div>
            </div>
          </template>
          <div class="p-inputgroup">
            <InputText
              class="w-full"
              :ref="'reply' + n.uid"
              placeholder="Comment"
              autofocus
              v-model="reply"
            />
            <Button
              style="
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              "
              icon="las la-paper-plane"
              @click="
                saveReply(n.uid);
                reply = null;
              "
              class="p-button-primary"
            />
            <Button
              icon="la la-times"
              class="p-button-rounded p-button-text p-button-secondary"
              @click="
                addReply = null;
                comment = null;
              "
            />
          </div>
        </Mentionable>
        <div v-else class="p-inputgroup">
          <Button
            label="Add a Comment"
            class="p-button-text p-button-secondary"
            @click="addReply = n.uid"
            icon="las la-plus"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Mentionable } from "vue-mention";
import {
  fetchNotes,
  fetchUsers,
  state,
} from "../../../services/data_service.js";
export default {
  name: "TaskNotes",
  components: {
    Mentionable,
  },
  props: {
    taskProp: {
      type: Object,
    },
  },
  data() {
    return {
      text: "",
      items: [],
      addNote: false,
      addReply: null,
      reply: null,
      title: null,
      note: null,
      isLoading: false,
      comment: null,
      activeTitle: null,
      activeNote: null,
      task: null,
    };
  },
  computed: {
    users() {
      state.users.map((a) => {
        a.value = a.username;
      });

      return state.users;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },
    notes() {
      return state.notes;
    },
  },
  created() {
    if (this.taskProp) {
      this.task = this.taskProp;
    }
  },
  async mounted() {
    await this.loadData();
  },
  watch: {
    taskProp(n) {
      this.task = n;
    },
  },
  destroyed() {
    state.notes = [];
  },
  methods: {
    async loadData() {
      await Promise.all([fetchNotes(this.task.uid), fetchUsers()]);
    },
    handleApply(el = null) {
      this.$nextTick(() => {
        setTimeout(() => {
          var ref = el === null ? this.$refs.comment : this.$refs[el][0];
          ref.$el.focus();
        });
      });
    },
    handleNoteTags(note) {
      this.users.forEach((u) => {
        const escapedUsername = u.username.replace(
          /[.*+?^${}()|[\]\\]/g,
          "\\$&"
        ); // Escape special characters
        note = note.replace(
          new RegExp("@" + escapedUsername, "g"),
          `<a class="cursor-pointer">@${u.username}</a>`
        );
      });

      return note;
    },
    dismiss() {
      this.$emit("dismiss");
    },
    async submitHandler() {
      this.loadingText = "Processing...";
      var form = new FormData();
      form.append("title", this.title);
      form.append("note_text", this.comment);
      form.append("task", this.task.uid);
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/note", form, {
          headers: { "Content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          fetchNotes(this.task.uid);
          // trunacate comment to 10 characters with elipsis...
          var notificationComment =
            this.comment?.length < 10
              ? this.comment
              : this.comment.substring(0, 15) + "...";
          this.$emit("dismiss");
          this.$toast.add({
            severity: "success",
            summary: "Note Saved",
            detail: notificationComment + " has been saved",
            life: 3000,
          });
          this.addNote = false;
          this.activeNote = null;
          this.title = null;
          this.comment = null;
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
    saveReply(u) {
      //this.isLoading = true
      var form = new FormData();
      form.append("note_text", this.reply);
      form.append("title", this.title);
      form.append("task", this.task.uid);
      form.append("note", u);
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/note", form, {
          headers: { "Content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          fetchNotes(this.task.uid);
          this.$emit("dismiss");
          this.activeTitle = null;
          this.comment = null;
          this.activeNote = null;
          this.addReply = null;
          this.rely = null;
          const uid = response.data.data.uid;
          this.isLoading = false;
          this.addNote = false;
          this.activeNote = null;
          this.title = null;
          this.comment = null;

          this.$toast.add({
            severity: "success",
            summary: "Comment Saved",
            life: 3000,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
  },
};
</script>
<style lang="scss">
.tooltip {
  z-index: 2000 !important;
  width: 250px !important;
  max-height: 300px !important;
  overflow-y: auto;
}

.mention-item {
  background: #ffffff;
}
.mention-selected {
  background: #4d7cfe;
  border-radius: 6px !important;
  color: white;
}

.vue-popover-theme {
  border-radius: 6px !important;
  padding: 6px !important;
  background-color: #ffffff;
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08),
    0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15);
}
</style>