<template>
  <div class="mt-2">
    <div class="formgrid grid flex align-items-center" v-if="!addTime">
      <div class="field col-12 pt-2 mb-1 ml-2 font-light" v-if="users == null">
        Assign users to allocate time
      </div>
      <Button
        label="Add Time"
        v-else-if="!addTime"
        class="p-button-text p-button-secondary"
        @click="addTime = !addTime"
        icon="las la-plus"
      />
      <Divider class="ml-2 mr-2 mb-3"></Divider>
    </div>

    <TaskTimeForm
      class="mb-5"
      :job_uid="job_uid"
      :task="task"
      @dismiss="addTime = !addTime"
      @isLoading="isLoading = false"
      :users="taskUsers"
      v-if="addTime"
    />

    <div
      v-if="captured_times.length > 0"
      class="flex align-items-center border-round pt-3 pb-3"
    >
      <div class="col-5 font-light">Timer</div>
      <div class="col"></div>
      <div class="col font-light">Time</div>
      <div class="col font-light">Date</div>
      <div class="col-grow-0" style="width: 40px"></div>
    </div>
    <div v-for="c in captured_times" :key="c.id" class="timerbar">
      <Divider class="m-2"></Divider>
      <TaskTimeForm
        class="pb-3"
        v-if="edit && edit.id === c.id"
        :job_uid="job_uid"
        :task="task"
        :users="taskUsers"
        @dismiss="edit = null"
        :editModel="edit"
      />
      <template v-else>
        <div class="flex align-items-center pt-2">
          <div class="col-5" v-if="users">
            {{ getUserName(c) }}
          </div>
          <div class="col">
            {{ c.time_spent > 0 ? "Timer" : "Manual" }}
          </div>
          <div class="col">
            {{
              c.commit_time > 0
                ? convertMinsToHrsMins(c.commit_time)
                : convertMinsToHrsMins(c.time_spent)
            }}
          </div>
          <div class="col">
            {{ c.commit_date }}
          </div>
          <div class="col-grow-0 invisible" style="width: 40px">
            <i
              @click="edit = c"
              class="las la-pen i-18 cursor-pointer grey mr-1"
            ></i>

            <i
              @click.stop="
                deletePromptRenderUpdate(
                  c.commit_message,
                  '/v1/tasks/time/',
                  c.id
                )
              "
              class="las la-trash i-18 cursor-pointer grey"
            ></i>
          </div>
        </div>
        <div class="flex col font-light pb-2 pt-1">
          {{ c.commit_message ? c.commit_message : "No Description" }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  state,
  fetchTaskTimes,
  fetchUsersData,
  fetchTaskUsers,
} from "../../../services/data_service.js";
import TaskTimeForm from "@/application/Tasks/components/TaskTimeForm";
export default {
  components: {
    TaskTimeForm,
  },
  props: {
    taskProp: {
      type: Object,
    },
  },

  data() {
    return {
      addTime: false,
      desc: null,
      time: null,
      date: null,
      timeUser: null,
      task_uid: null,
      isLoading: false,
      edit: null,
      menuItems: [
        {
          label: "Edit",
        },
        {
          label: "Delete",
        },
      ],
    };
  },
  async mounted() {
    if (!this.obj) {
      state.captured_times = [];
    }

    await Promise.all([
      fetchTaskTimes(this.task.uid),
      fetchUsersData(),
      fetchTaskUsers(this.task.uid),
    ]);
  },
  created() {
    if (this.taskProp) {
      this.task = this.taskProp;
      this.task_uid = this.task.uid;
    }
  },
  beforeDestroy() {
    state.captured_times = [];
  },
  computed: {
    users() {
      return state.usersData.users;
    },
    taskUsers() {
      return state.taskUsers;
    },
    captured_times() {
      return state.captured_times;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },
    job_uid() {
      return state.task.job.uid;
    },
  },
  watch: {
    taskProp(n) {
      this.task = n;
    },
    computedCapturedTimes(newValue) {
      // Update the data property whenever the computed value changes
      this.captured_times = newValue;
    },
  },
  methods: {
    async deletePromptRenderUpdate(title, url, id) {
      try {
        const userClickedYes = await this.deletePrompt(title, url + id);
        if (userClickedYes) {
          state.captured_times = this.captured_times.filter(
            (time) => time.id !== id
          );
        } else {
          console.log("No Update");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    convertMinsToHrsMins(minutes) {
      var h = Math.floor(minutes / 60);
      var m = minutes % 60;
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      return h + ":" + m;
    },
    toggle(event) {
      this.$refs.menu[0].toggle(event);
    },
    getUserName(c) {
      for (var x = 0; x < this.users.length; x++) {
        if (this.users[x].uid === c.user_uid) {
          return this.users[x].name;
        }
      }
    },
    deleteItem(c) {
      this.isLoading = true;
      this.$axios
        .delete(process.env.VUE_APP_ROOT_API + "/v1/tasks/time/" + c.id, {})
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Time Deleted",
            life: 3000,
          });
        })
        .catch((e) => {
          this.errors.push(e);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
</style>