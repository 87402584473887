<template>
  <div style="max-width: 300px">
    <div
      v-if="taskTags?.length < 1"
      @click="routeTags()"
      class="cursor-pointer"
    >
      <a>Create a tag</a>
    </div>
    <template v-else>
      <div class="pb-3">Global Tags</div>

      <span v-for="t in taskTags" :key="t.uid">
        <TaskTag :task="task" :canRemove="false" :tag="t"></TaskTag> </span
    ></template>
  </div>
</template>

<script>
import { state } from "../../../services/data_service.js";
export default {
  props: {
    task: {
      type: Object,
    },
  },

  computed: {
    taskTags() {
      return state.taskTags;
    },
  },
  methods: {
    routeTags() {
      this.clearSliderContent();
      this.$router.push("/settings/global_tags");
    },
  },
};
</script>

<style>
</style>